import React, { useEffect, useState, useContext } from 'react';
import { useAuth } from './Auth';
import { db } from './base'

export const ProfileContext = React.createContext();
export const ProfileProvider = ({ children }) => {
    const [userProfile, setUserProfile] = useState(null);
    const { currentUser } = useAuth()
    useEffect(() => {
        if (currentUser) {
            let mounted = true;
            db.collection('users').doc(currentUser.uid).get().then(docRef => {
                if (mounted) {
                    setUserProfile(docRef.data())
                }
            })
            return () => mounted = false
        }
    }, [currentUser]);

    return (
        <ProfileContext.Provider
            value={userProfile}
            displayName="Profile Display Name"
        >
            {children}
        </ProfileContext.Provider>
    )
}