import styled from 'styled-components'

import logo from '../../assets/logoFullReversed.svg'
import loginBg from '../../assets/bg-login.jpg'
import SVG from 'react-inlinesvg'
import styles from '../../styles'

export const Logo = styled(SVG).attrs((props) => ({
    src: logo
}))`
    margin-bottom: 20px;
    height: 70px;
    width: auto;
`
const Input = styled.input`
    border-radius: 5px;
    height: 50px;
    width: 80%;
    background: transparent;
    border: none;
    color: #fff;
    outline: none;
    padding-left: 10px;

    & input:-internal-autofill-selected {
        background-color: transparent;
    }
`
export const FormWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-image: url(${loginBg});
    background-size: cover;
    background-repeat: none;
    background-position: bottom center;
    /* background: linear-gradient(25deg, rgba(19,19,19,1) 0%, rgba(91,11,11,1) 60%, rgba(158,19,19,1) 100%); */
`
export const FormInner = styled.div`
    background: rgba(0, 0, 0, 0.8);
    border-radius: 35px;
    padding: 40px 20px;
    max-width: 500px;
    margin: 6em auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 320px) {
        width: 70%;
    }
`
export const FormGroup = styled.div`
    background: rgba(255, 255, 255, 0.15);
    display: flex;
    margin-bottom: 20px;
    padding: 2px 10px 2px 0;
    border-radius: 35px;
    padding: 2px 10px 2px 0;
`

export const IconWrapper = styled.div`
    width: 20%;
    line-height: 49px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;
    color: #fff;
`
export const Email = styled(Input).attrs(props => ({
    type: 'text',
    placeholder: 'Email'
}))``
export const Password = styled(Input).attrs(props => ({
    type: 'password',
    placeholder: 'Password'
}))``

export const Submit = styled.button.attrs((props) => ({
    type: 'submit',
    disabled: !props.disabled
}))`
    background: ${styles.primary};
    border: none;
    color: #fff;
    padding: 11px 15px;
    text-transform: uppercase;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
    letter-spacing: 2px;
    cursor: pointer;
    border-radius: 35px;
    transition: 0.5s all;

    &:hover {
        background: ${styles.primaryDarken}
    }
    &:disabled {
        cursor: not-allowed
    }
`