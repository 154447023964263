import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import logoFull from '../../assets/logoFull.svg'
import logoOnly from '../../assets/logoGraphics.svg'


const LogoFull = styled(SVG)`
    height: 60px;
    margin: 10px 0 0 0;

`;
const LogoOnly = styled(SVG)`
    height: 40px;
    margin: 5px 0 10px 10px;
    & path.cls-2 {
        fill: #ffffff;
    }
`
const Logo = ({size}) => {
    return (
        <>
        {size && size === 'full' ? <LogoFull src={logoFull} /> : <LogoOnly src={logoOnly} />}
        </>
     )
}

export default Logo