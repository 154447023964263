import React from 'react'
import aboutImage from '../../assets/about-image.png'
import {infoSectionOne} from '../../data'
import { HighLight } from '../AppElements';
import {
    Wrapper,
    Container,
    Graphic,
    InfoSection,
    InfoTitle,
    TopLine,
    Description,
    Details,
    FeaturesList,
    ActionButton,
    FeaturesSection,
    Badge,
    BtnWrapper
} from './AboutElements'

const About = () => {
    return (
        <>
            <Wrapper name="about">
                <Container>
                <Graphic>
                    <img src={aboutImage} alt="about"/>
                </Graphic>
                <InfoSection>
                    <TopLine>{infoSectionOne.topline}</TopLine>
                    <InfoTitle>{infoSectionOne.headline}<HighLight>.</HighLight></InfoTitle>
                    <Description><HighLight>{infoSectionOne.description}</HighLight></Description>
                    <Details>{infoSectionOne.details}</Details>
                    <FeaturesSection>
                        <FeaturesList items={infoSectionOne.features} />
                        <Badge><span>Since<br/>2003</span></Badge>
                    </FeaturesSection>
                    <BtnWrapper>
                        <ActionButton to="contact" smooth='easeInOutQuad'>Contact Us</ActionButton>
                    </BtnWrapper>
                </InfoSection>
                </Container>
            </Wrapper>
        </>
    )
}

export default About
