import { MenuItem, Switch, TextField } from "@material-ui/core"
import { Controller } from "react-hook-form"
import { sanitizeValue } from "./ProfileElements"

const boolean = ({ name, control, value }) => <Controller
    name={name}
    control={control}
    defaultValue={sanitizeValue(value)}
    render={({ field }) => <Switch {...field} />}
/>

const string = ({ name, control, value }) => <Controller
    name={name}
    control={control}
    defaultValue={sanitizeValue(value)}
    render={({ field }) => <TextField {...field} style={{ width: value ? value.length * 9 : '150px', marginLeft: '10px', maxWidth: '90%' }} />}
/>

const select = ({ name, control, value }) => <Controller
    name={name}
    control={control}
    defaultValue={sanitizeValue(value)}
    render={({ field }) => <TextField select {...field} style={{ width: '40px', marginLeft: '10px' }}>
        {[0, 1, 2].map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
    </TextField>}
/>

const readOnly = ({ name, control, value }) => <Controller
    name={name}
    control={control}
    defaultValue={sanitizeValue(value)}
    render={({ field }) => <TextField InputProps={{ readOnly: true }} {...field} style={{ width: value ? value.length * 9 : '150px', marginLeft: '10px' }} />}
/>
const FormElements = {
    boolean,
    string,
    select,
    readOnly
}
export default FormElements