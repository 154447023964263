import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid';
import { RiComputerLine, RiDragDropFill } from 'react-icons/ri'
import {FaMobileAlt, FaUsersCog, FaUsers} from 'react-icons/fa'
import {AiOutlineFileSearch} from 'react-icons/ai'
import { HighLight } from '../AppElements';
import {
    Bg,
    Wrapper,
    Title,
    SubTitle,
    Box,
    Number,
    Graphic,
    BoxTitle,
} from './ServicesElements'

const Services = () => {
    return (
        <Bg>
        <Wrapper name="services">
            <Grid fluid>
                <Row>
                    <Col sm={12} lg={6}>
                        <Title>We shape the perfect solutions<HighLight>.</HighLight></Title>
                        <SubTitle>We are committed to providing our customers with exceptional
                            service while crafting solutions perfectly suited for their needs.</SubTitle>
                    </Col>
                    <Col lg={3} md={6}>
                        <Box>
                            <Number>01</Number>
                            <Graphic><RiComputerLine /></Graphic>
                            <BoxTitle>Website <br/>Development</BoxTitle>
                        </Box>
                    </Col>
                    <Col lg={3} md={6}>
                        <Box>
                            <Number>02</Number>
                            <Graphic><FaMobileAlt /></Graphic>
                            <BoxTitle>App <br/> Development</BoxTitle>
                        </Box>
                    </Col>
                    <Col lg={3} md={6}>
                        <Box>
                            <Number>03</Number>
                            <Graphic><RiDragDropFill /></Graphic>
                            <BoxTitle>Graphic <br/> Design</BoxTitle>
                        </Box>
                    </Col>
                    <Col lg={3} md={6}>
                        <Box>
                            <Number>04</Number>
                            <Graphic><FaUsers /></Graphic>
                            <BoxTitle>UI/UX  <br/>Design</BoxTitle>
                        </Box>
                    </Col>
                    <Col lg={3} md={6}>
                        <Box>
                            <Number>05</Number>
                            <Graphic><AiOutlineFileSearch /></Graphic>
                            <BoxTitle>SEO & Content <br/> Writing</BoxTitle>
                        </Box>
                    </Col>
                    <Col lg={3} md={6}>
                        <Box>
                            <Number>06</Number>
                            <Graphic><FaUsersCog /></Graphic>
                            <BoxTitle>Digital<br/>Marketing</BoxTitle>
                        </Box>
                    </Col>
                </Row>
            </Grid>
        </Wrapper>
        </Bg>
    )
}

export default Services
