export const DashboardLinks = [
    {
        name: 'users',
        protected: true,
        level: 2
    },
    {
        name: 'account',
        protected: false
    },
    {
        name: 'profile', 
        protected: false,
    }
]