import { AiOutlineHome } from 'react-icons/ai'
import StorageIcon from '@material-ui/icons/Storage';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LockIcon from '@material-ui/icons/Lock';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';

const iconDictionary = {
    home: AiOutlineHome,
    Home: AiOutlineHome,
    Projects: AccountTreeOutlinedIcon,
    Messages: TextsmsOutlinedIcon,
    Documents: InsertDriveFileOutlinedIcon,
    Admin: LockIcon,
    Database: StorageIcon,
    Users: GroupAddOutlinedIcon,
    Dashboard: DashboardOutlinedIcon,
    Invoices: ReceiptIcon,
    users: PeopleIcon,
    profile: PersonIcon,
    account: PersonIcon,
}

const IconFactory = name => {
      const TagName = iconDictionary[name]
    return <TagName/>
}

export default IconFactory