import React, { useCallback } from 'react'
import styled from 'styled-components'
import styles from '../../styles'
import { useForm } from 'react-hook-form'
import * as fe from '../../components/FormElements'
import signupBg from '../../assets/bg-signup.jpg'
import { useHistory } from 'react-router'
import { HiOutlineMail } from 'react-icons/hi'
import { FiLock } from 'react-icons/fi'
import { dbSignUp } from '../../base'
import { saveProfileLocal } from '../../appFunctions'



const Wrapper = styled(fe.FormWrapper)`
    background-image: url(${signupBg});
    background-color: rgba(0,0,0,.5);
`

const HomeLink = styled.div`
    cursor: pointer;
`
const SignupMessage = styled.div`
    color: #fff;
    margin: 25px 0 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SignupLink = styled.a`
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
    color: ${styles.primary};
`
const SignUp = () => {
    const history = useHistory()
    const toHome = () => history.push('/')
    const toLogin = () => history.push('/login')
    const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
    const onSubmit = useCallback(
        async ({email, password}) => {
            const {success, user, error} = await dbSignUp({email, password})
            if (success) {
                saveProfileLocal(user)
                history.push('/account')
            } else {
                console.error('Error', error)
            }
        }, [history]
    )
    // const onSubmit = d => console.log(d)
    return (
        <Wrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fe.FormInner>
                    <HomeLink onClick={toHome}>
                        <fe.Logo />
                    </HomeLink>
                    <fe.FormGroup>
                        <fe.IconWrapper><HiOutlineMail /></fe.IconWrapper>
                        <fe.Email {...register("email", { required: true })} />
                    </fe.FormGroup>
                    <fe.FormGroup>
                        <fe.IconWrapper><FiLock /></fe.IconWrapper>
                        <fe.Password {...register("password", { required: true })} />
                    </fe.FormGroup>
                    <fe.Submit disabled={formState.isValid}>Sign Up</fe.Submit>
                    <SignupMessage>Already have an account? <SignupLink onClick={toLogin}>Log In</SignupLink></SignupMessage>
                </fe.FormInner>
            </form>
        </Wrapper>
    )
}

export default SignUp
