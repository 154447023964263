import React from 'react'
import { NavLinks } from '../../data'
import { 
    CloseIcon, 
    Icon, 
    SidebarContainer, 
    SidebarRoute,
    SideBtnWrap,
    SidebarWrapper,
    SidebarLink,
    SidebarMenu,
} from './SidebarElements'
const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {NavLinks.map((link, idx) => (
                        link.scroll
                        ? <SidebarLink key={idx} to={link.name} onClick={toggle}>{link.label}</SidebarLink>
                        : ''
                    ))}
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to="/login">Log In</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
