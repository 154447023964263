 import React, { useRef, useState } from 'react'
import firebase from 'firebase'
import { db } from '../../base'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useAuth } from '../../Auth';
import styled from 'styled-components';
import { Avatar, Button, makeStyles, TextField } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { getProfilePicture } from '../../appFunctions';


const useStyles = makeStyles({
    root: {
        width: '20px',
        height: '20px',
    },
    chatInput: {
        width: '80%'
    },
    messageWrapper: {
        marginBottom: '10px'
    }
})

const ChatWindow = styled.div`
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: ${props => props.height ? `${props.height}px` : 'auto'};
    display: flex;
    flex-direction: column;
`
const MessagesWrapper = styled.div`
    flex: 1 0 80%;
    padding: 5px;
    overflow-y: scroll;
`
const MessageAvatar = styled(Avatar).attrs({
    src: getProfilePicture()
})`
    margin: ${({type}) => type === 'sent' ? '0 0 10px 10px' : '0 10px 10px 0'};
`

const FormWrapper = styled.div`
    display: flex;
    width: 100%;
`
const FormInput = styled(TextField)`
    flex: 1;
    height: 40px;
`
const MessageWrapper = styled.div`
    display: flex;
    /* justify-content: ${props => props.type === 'sent' ? 'flex-start' : 'flex-end'}; */
    flex-direction: ${props => props.type === 'sent' ? 'row-reverse' : 'row'};
`

const MessageText = styled.div`
    text-align: 'start';
`

const ChatMessage = ({ message, user }) => {
    const classes = useStyles()
    const { text, uid, photoURL } = message;
    const messageClass = uid === user.uid ? 'sent' : 'received'
    return (
        <MessageWrapper type={messageClass} className={classes.messageWrapper}>
            <MessageAvatar type={messageClass} className={classes.root} />
            <MessageText>{text}</MessageText>
        </MessageWrapper>
    )
}


const Chat = ({ height }) => {
    const classes = useStyles()
    const dummy = useRef()

    const { currentUser } = useAuth()

    const messagesRef = db.collection('messages')
    const query = messagesRef.orderBy('createdAt').limit(25);

    const [messages] = useCollectionData(query, { idField: 'id' })

    const [formValue, setFormValue] = useState('')

    const sendMessage = async (e) => {
        e.preventDefault()
        const { uid, photoURL } = currentUser;
        await messagesRef.add({
            text: formValue,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            uid,
            photoURL
        });
        setFormValue('')
        dummy.current.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <ChatWindow height={height}>
            <MessagesWrapper>
                {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} user={currentUser} />)}
                <div ref={dummy}></div>
            </MessagesWrapper>
            <form onSubmit={sendMessage}>
                <FormWrapper>
                    <FormInput fullWidth size="small" variant="outlined" value={formValue} onChange={e => setFormValue(e.target.value)} />
                    <Button variant="contained" type="submit" disabled={formValue === ''}>Send</Button>
                </FormWrapper>
            </form>
        </ChatWindow>
    )
}

export default Chat
