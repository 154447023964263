import React, {useCallback, useContext, useEffect} from 'react'
import { withRouter, Redirect } from 'react-router'
import { useForm } from 'react-hook-form'
import { AuthContext, useAuth } from '../../Auth'
import {
    LoginForm, SignupLink, SignupMessage,
} from './LoginElements'
import app, { getProfile } from '../../base'
import { saveLocal } from '../../appFunctions'

const Login = ({history}) => {
    const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
    const handleLogin = useCallback(
        async ({email, password}) => {
            try {
               const {user} =  await app
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                const profile = await getProfile(user)
                console.log('profile', profile)
                saveLocal('profile', profile)
            } catch (error) {
                alert(error)
            }
        },
        []
    )

    const {currentUser} = useContext(AuthContext)

    if (currentUser) {
        return <Redirect to="/account" />
    }

    return (
        <>
            <LoginForm onSubmit={handleLogin} handleSubmit={handleSubmit} register={register} formState={formState} />
        </>
    )
} 

export default withRouter(Login)
