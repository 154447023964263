import {useState} from 'react'
import styled from "styled-components";
import styles from '../styles'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import ReactScrollableList from 'react-scrollable-list'
import {BiEdit} from 'react-icons/bi'

export const Container = styled.div`
    max-width: 1100px;
    width: 100%;
    height: 100%;
`
export const RaisedImg = styled.img`
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, 
    rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
    rgb(0 0 0 / 12%) 0px 1px 8px 0px;
`
export const HighLight = styled.span`
    color: ${styles.primary};
`
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowY: 'scroll',
    height: '150px'
  },
}));

export default function CheckboxList({data, handleEdit}) {
  const classes = useStyles();
  const [checked, setChecked] = useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List className={classes.root}>
      {data.map((value, idx) => {
        const labelId = `checkbox-list-label-${idx}`;

        return (
          <ListItem key={idx} role={undefined} dense button onClick={handleToggle(idx)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(idx) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.title} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments" onClick={() => handleEdit(value)}>
                <BiEdit />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

export const ScrolList = (data) => {
    return (
        <ReactScrollableList
            listItems={data}
            heightOfItem={30}
            maxItemsToRender={50}
            style={{ color: '#333' }}
            />
    )
}