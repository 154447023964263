import React from 'react'
import { Box, Divider, Hidden, Link, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { capitalCase } from 'change-case';

import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Chat from '../../components/Chat';
import IconFactory from '../../IconFactory';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    height: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  listItem: {
    color: "rgba(158, 19, 19, 0.8)"
  }
}));


const SectionHeader = styled.div`
  font-weight: bold;
  border-bottom: 1px solid rgba(0,0, 0, 0.2);
  margin-bottom: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(158, 19, 19, 0.8);
  color: #fff;
  border-radius: 10px 10px 0 0;
`
const SectionNode = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 10px;
`
const Label = styled.div`
  font-weight: bold;
  margin-right: 10px;
`
const TaskList = ({ items }) => {
  return (
    <List>
      {items.map((task, idx) => (
        <React.Fragment key={idx}>
          <ListItem>
            <Hidden smDown>
              <ListItemIcon>
                <FormatListNumberedIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText primary={task.name} />
            <ListItemSecondaryAction>
              {task.status}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}
const InvoiceList = ({ invoices }) => {
  const classes = useStyles()
  const openLink = url => {
    window.open(url)
  }
  return (
    <List>
      {invoices.map((invoice, idx) => (
        <React.Fragment key={idx}>
          <ListItem key={invoice.title} button onClick={() => openLink(invoice.link)}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary={invoice.title} className={classes.listItem}/>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}
const DocumentationList = ({ items }) => {
  const classes = useStyles()
  const openLink = url => {
    window.open(url)
  }
  return (
    <List>
      {items.map((task, idx) => (
        <React.Fragment key={idx}>
          {task.links ? task.links.map((link, linkKey) => (
            <React.Fragment key={linkKey}>
              <ListItem button onClick={() => openLink(link.link)}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={link.name} className={classes.listItem}/>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
            : null}
        </React.Fragment>
      ))}
    </List>
  )
}
const Value = styled.div``
const GridItem = styled(Grid)`
  height: ${props => props.height ? props.height : 'auto'};
`
export const GridLayout = ({ doc }) => {
  const classes = useStyles();
  const fullName = `${doc.contact.firstName} ${doc.contact.lastName}`
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper}>
            <SectionHeader>Project Info</SectionHeader>
            <SectionNode>
              <Label>Project Name:</Label>
              <Value>{doc.projectName}</Value>
            </SectionNode>
            <SectionNode>
              <Label>Start Date:</Label>
              <Value>{doc.startDate}</Value>
            </SectionNode>
            <SectionHeader>Contact Info</SectionHeader>
            <SectionNode>
              <Label>Contact Name:</Label>
              <Value>{fullName}</Value>
            </SectionNode>
            <SectionNode>
              <Label>Email:</Label>
              <Value>{doc.contact.email}</Value>
            </SectionNode>
            <SectionNode>
              <Label>Phone:</Label>
              <Value>{doc.contact.phone}</Value>
            </SectionNode>
            <SectionNode>
              <Label>Website Url:</Label>
              <Value>{doc.contact.website}</Value>
            </SectionNode>
          </Paper>
        </Grid>
        <GridItem item height='300px' xs={12} md={3}>
          <Paper className={classes.paper}>
            <SectionHeader>Links</SectionHeader>
            <DocumentationList items={doc.tasks.preWork} />
          </Paper>
        </GridItem>
        <GridItem item height='300px' xs={12} md={3}>
          <Paper className={classes.paper}>
            <SectionHeader>Invoices</SectionHeader>
            <InvoiceList invoices={doc.invoices} />
          </Paper>
        </GridItem>
        <GridItem item height='300px' xs={12} md={6}>
          <Paper className={classes.paper}>
            <SectionHeader>Messages</SectionHeader>
            <Chat height="200"/>
          </Paper>
        </GridItem>
        <GridItem item height='300px' xs={12} md={6}>
          <Paper className={classes.paper}>
            <SectionHeader>Tasks</SectionHeader>
            <TaskList items={doc.tasks.preWork} />
          </Paper>
        </GridItem>
      </Grid>
    </div>
  );
}

export const DashboardLink = ({link, notify}) => {
  return (
    <ListItem button onClick={() => notify({type: 'go', payload: link})}>
      <ListItemIcon>
        {IconFactory(link.name)}
      </ListItemIcon>
      <ListItemText primary={capitalCase(link.name)}/>
    </ListItem>
  )
}