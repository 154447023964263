import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { deleteLocalProfile } from './appFunctions';

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

export const db = app.firestore();
export const storage = app.storage 
export const dbGet = async (collectionName, query = false) => {
  const data = await db.collection(collectionName).get()
  return query ? ''
    : data.docs.map(doc => ({...doc.data(), id: doc.id}))
}
export const createDoc = ({collection, data}) => {
  return db.collection(collection)
    .add({
      created: firebase.firestore.FieldValue.serverTimestamp(),
      ...data
    })
}
export const dbLogout = () => {
  firebase.auth().signOut();
  deleteLocalProfile()
}
export const AuthLogOut = ({cb}) => (<button onClick={() => {firebase.auth().signOut(); deleteLocalProfile(); cb()}}>Log Out</button>)
function getAccessLevel(name = false) {
  const accessLevels = ['user', 'admin', 'superAdmin']
  if(!name) return 0
  return accessLevels.indexOf(name)
}
function getProfileData({user}) {
  const profile =  ["photoURL", "displayName",  "firstName", "lastName", "email", "phoneNumber",  "uid", "isAdmin", "level", "lastUpdated"].reduce((m, i) => {
    m[i] = user[i] || null
    return m
  }, {})
  return {...profile, isAdmin: false, firstName: null, lastName: null, level: getAccessLevel()}
}
export const dbSignUp = async ({email, password}) => {
  const cred = await firebase.auth()
    .createUserWithEmailAndPassword(email, password)
    const profile = getProfileData(cred)
  const user = await db.collection('users')
        .doc(profile.uid)
        .set(profile)
  return user === undefined ? {success: true, user: profile} : {success: false, error: user}
}
export default app;
export const getProfile = async ({uid}) => {
  const profile = await db.collection('users')
    .doc(uid)
    .get()
    return {id: profile.id, ...profile.data()}
}
export const createProfile = async ({email, phone = false, firstName = '', lastName = '', isAdmin = false}) => {
  await db.collection('users').doc(email).set({email, phone, firstName, lastName, isAdmin})
}


