import React from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { PrivateRoute } from '../../components/Routing';
import { useAuth } from '../../Auth';


import AccountLayout from './AccountLayout'
import { GridLayout } from './AccountElements'
import Admin from '../../components/Admin';
import { Box, Container } from '@material-ui/core';
import Profile from '../../components/Profile';
import { ProfileContext, ProfileProvider } from '../../Profile';
const Account = () => {
    const doc = {
        projectName: "Pillnerd",
        startDate: '7/29/2021',
        contact: {
            firstName: "Lisa",
            lastName: 'Timberlake',
            email: "hello@pillnerd.com",
            phone: '',
            website: 'pillnerd.com'
        },
        tasks: {
            preWork: [
                { name: 'Select hosting that meets HIPAA Requirements', status: 'complete', links: [{ name: "HIPAA Documentation", link: "https://cloud.google.com/security/compliance/hipaa/#customer_responsibilities" }] },
                { name: 'Setup backend on hosting', status: 'Not Started' },
                { name: 'Design Application UI Prototype', status: 'Not Started' }
            ],
            regular: [
            ]
        },
        messages: [],
        invoices: [
            { title: "Payment to start Work", link: 'https://buy.stripe.com/cN25mx7XraFg67C5kk' }
        ]
    }
    let { path, url } = useRouteMatch();
    const { currentUser } = useAuth()
    const history = useHistory();
    const reRoute = () => history.push("/")
    const notify = ({ type, payload }) => {
        if (type === 'go' && payload.name === 'account') {
            history.push('/account')
            return
        }
        if (type === 'go') history.push(`${path}/${payload.name}`)
    }
    return (
        <ProfileContext.Consumer>
            {userProfile => (
                <AccountLayout userProfile={userProfile} basePath={path} notify={notify}>
                    <Switch>
                        <Route exact path={path}>
                            <GridLayout doc={doc} />
                        </Route>
                        <Route path={`${path}/profile`}>
                            <Profile user={currentUser} />
                        </Route>
                    </Switch>
                </AccountLayout>
            )}
        </ProfileContext.Consumer>
    )
}

export default Account
