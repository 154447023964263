import React from 'react'
import styled from "styled-components";
import {FaBars} from 'react-icons/fa'
import Logo from '../LogoElements';
import { HeaderLeft, HeaderRight, NavMenu, MobileIcon } from './HeaderElements'
import styles from '../../styles'


const HeaderWrapper = styled.div`
    width: 100%;
    background: ${styles.bg};   
    justify-content: center;    
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8 all ease;
    }
`
const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px;
    transition: all 0.2s ease-in-out;
    align-self: center;
`

const Header = ({toggle}) => {

    const appStyles = {
        theme: 'dark'
    }
    return (
        <>
            <HeaderWrapper>
                <Container>
                <HeaderLeft>
                    <Logo />
                </HeaderLeft>
                <HeaderRight>
                    <MobileIcon onClick={toggle}>
                        <FaBars/>
                    </MobileIcon>
                    <NavMenu theme={appStyles.theme}/>
                </HeaderRight>
                </Container>
            </HeaderWrapper>
        </>
    )
}

export default Header
