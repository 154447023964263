import styled from 'styled-components'
import styles from '../../styles'
export const Wrapper = styled.div`
    display: flex;
    max-width: 1200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 20px 0;
`

export const Title = styled.h2`
    font-size: 70px;
    line-height: 0.9em;
    text-transform: uppercase;
    color: black;
    font-weight: 400;
    margin-bottom: 0px;
    @media screen and (max-width: 480px) {
        margin-left: 40px;
    }
`
export const AppInput = styled.input`
    font-size: 15px;
    height: 50px;
    width: 85%;
    margin-top: 20px;
    color: #000;
    line-height: 40px;
    font-weight: 400;
    padding: 14px 30px;
    letter-spacing: 0.02em;
    background-color: #f4f5f8;
    border: 1px solid #f4f5f8;
    border-radius: 7px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;

    
    @media screen and (max-width: 480px) {
        padding: 14px 26px;
    }
    
    @media screen and (min-width: 481px) and (max-width: 980px) {
        padding: 14px 57px;
    }
`
export const TextAreaWrapper = styled.div`
    padding-right: 5px;
`
export const AppTextArea = styled.textarea`
    position: relative;
    font-family: 'Teko', sans-serif;
    display: block;
    margin-top: 20px;
    height: 100px;
    width: 93%;
    font-size: 18px;
    line-height: 40px;
    font-weight: 400;
    padding: 14px 30px;
    letter-spacing: 0.02em;
    background-color: #f4f5f8;
    border: 1px solid #f4f5f8;
    border-radius: 7px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;

    @media screen and (max-width: 480px) {
        padding: 14px 18px;
    }
`

export const BtnWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ActionButton = styled.button`
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 19px 50px 16px;
    background: ${styles.primary};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #fff;
    border-color: transparent;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    margin-top: 20px;
`