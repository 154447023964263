import React, {useState} from 'react'
import Video from '../../assets/video.mp4'
import {Button} from '../../components/ButtonElements'
import { 
    HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper, 
    ArrowRight,
    ArrowForward
 } from './HeroElements'


const Hero = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    return (
        <>
            <HeroContainer name="home">
                <HeroBg>
                    <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
                </HeroBg>
                <HeroContent>
                <HeroH1>Your Complete Web and Software Design Solution</HeroH1>
                <HeroP>From concept to deployment & maintenance, we have the team and experience to bring your vision to life.</HeroP>
                <HeroBtnWrapper>
                    <Button 
                    to="contact" 
                    smooth="easeInOutQuad"
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}
                    >
                        Get Started {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
            </HeroContainer>
        </>
    )
}

export default Hero
