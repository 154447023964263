import React from 'react'
import { HighLight } from '../AppElements'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {useForm} from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast';

import { 
    Wrapper,
    Title,
    AppInput,
    AppTextArea,
    ActionButton,
    TextAreaWrapper,
    BtnWrapper,
} from './ContactElements'

const Contact = () => {
    const { register, handleSubmit, reset } = useForm();
    const notify = () => toast.success("Thank you, we will contact you shortly.");
    const onSubmit = data =>{ 
        notify()
        reset()
    };
 
    return (
        <Wrapper name="contact">
            <Title>Write us a message<HighLight>.</HighLight></Title>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Grid fluid>
                <Row>
                    <Col lg={6}>
                        <AppInput {...register("name")} placeholder="Your Name"></AppInput>
                    </Col>
                    <Col lg={6}>
                        <AppInput {...register("eamil")} placeholder="Email Address"></AppInput>
                    </Col>
                    <Col lg={6}>
                        <AppInput {...register("phone")} placeholder="Phone Number"></AppInput>
                    </Col>
                    <Col lg={6}>
                        <AppInput {...register("subject")} placeholder="Subject"></AppInput>
                    </Col>
                    <Col lg={12}>
                        <TextAreaWrapper>
                            <AppTextArea {...register("message")} placeholder="Message"></AppTextArea>
                        </TextAreaWrapper>
                    </Col>
                </Row>
            </Grid>
            <BtnWrapper>
                <ActionButton type="submit">Send Message</ActionButton>
            </BtnWrapper>
            </form>
            <Toaster />
        </Wrapper>
    )
}

export default Contact
