import React from 'react'
import {
    Header,
    Hero,
    About,
    Services,
    Contact
} from '../components'
import Footer from '../components/Footer'
import {infoSectionOne} from '../data'

const Home = ({toggle}) => {

    return (
        <>
            <Header toggle={toggle}/>
            <Hero />
            <About {...infoSectionOne}/>
            <Services />
            <Contact />
            <Footer/>
        </>
    )
}

export default Home
