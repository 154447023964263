import { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import styled, { ThemeProvider } from "styled-components";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Home from './pages/Home';
import Login from './pages/Login';
import Sidebar from './components/Sidebar';
import Account from './pages/Account';
import { PrivateRoute } from './components/Routing'
import { AuthProvider } from './Auth';
import SignUp from './pages/Signup';
import theme from './_Theme';
import { ProfileProvider } from './Profile';

const AppMain = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

function App() {
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!isOpen)
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider value>
        <ProfileProvider value>
        <Router>
          <Switch>
            <Route exact path="/">
              <AppMain>
                <Sidebar isOpen={isOpen} toggle={toggle} />
                <Home toggle={toggle} />
              </AppMain>
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            {/* <Route path="/account">
            <Account />
          </Route> */}
            <PrivateRoute path="/account">
              <Account />
            </PrivateRoute>
          </Switch>
        </Router>
        </ProfileProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App;
