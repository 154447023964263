import styled from 'styled-components'
import { isBoolean, isNull, isNumber, isObject, isString } from '../../_DataHelpers'
import FormElements from './FormGen'

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
`

export const ListItemLabel = styled.div`
    font-weight: bold;
    margin-right: 10px;
    &:after {
        content: ':'
    }
`
export const ListItemValue = styled.div`
  
`

export const sanitizeValue = value => {
    if(typeof value === 'boolean') return value.toString() === 'false' ? 'No' : 'Yes'
    if(value === null) return ''
    return value
}
export const ProfileForm = styled.form`
`


export const AutoFormInput = ({name, value, control, noEditList}) => {
    console.log(name, value, typeof value, isObject(value))
    const timeStampList = ['lastUpdated']
    if (noEditList.includes(name)) return FormElements.readOnly({name, value, control})
    if (timeStampList.includes(name)) return FormElements.readOnly({name, value: new Date(value * 1000), control})
    if (isNull(value)) return FormElements.string({name, value, control})
    if (isString(value)) return FormElements.string({name, value, control})
    if (isBoolean(value)) return FormElements.boolean({name, value, control})
    if (isNumber(value) && value.toString().length < 3) return FormElements.select({name, value, control})

    return (null)
}

