import React, {useEffect, useState, useContext} from 'react';
import app from './base'

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext)
export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        app.auth().onAuthStateChanged(setCurrentUser);
    }, []);

    return (
        <AuthContext.Provider 
            value={{currentUser}}
            displayName="Context Display Name"
        >
            {children}
        </AuthContext.Provider>
    )
}