import styled from "styled-components";
import {Link as LinkS} from 'react-scroll'
import { NavLinks as links } from "../../data";
import {LogoutButton, PageButton} from '../ButtonElements'
import styles from '../../styles'
import { useAuth } from "../../Auth";
import { dbLogout } from "../../base";

export const Slider = styled.span`
    position: absolute;
    display:block;
    left: 0;
    top:90%;
    margin:0 auto;
    height: 2px;
    background-color: ${styles.primary};
    width: 0%;
    transition: width 0.3s ease;
    `
 export const ScrollLink = styled(LinkS)`
     cursor: pointer;
     position:relative;
    display:inline-block;
    transition: all 0.3s ease-out;
     &:hover {
         color: ${styles.primary};
     }
     &:hover span {
         width: 100%;
     }
 `
 export const HeaderLeft = styled.div``
 export const HeaderRight = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 0 0 50%;
    position: relative;
`
export const Menu = styled.ul`
    flex: 1 1 100%;
    display: flex;
    list-style: none;
    justify-content: space-around;
    color: ${({theme}) => (theme === 'dark' ? '#fff' : '#000')};

    @media screen and (max-width: 768px) {
        display: none;
    }

    & li {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const MenuButton = styled.button`
    background: ${styles.primary};
    border-radius: 50px;
    outline: none;
    border: none;
    padding: 5px 20px;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${styles.primaryDarken};
    }
`

export const MobileIcon = styled.div`
    display: none;

    & path {
        fill: ${styles.primary};
    }

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: -10px;
        right: -20px;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`

export const NavMenu = ({theme}) => {
    const {currentUser} = useAuth()
    const isSignedIn = currentUser ? true : false
    return (
        <Menu theme={theme}>
            {links.map((link, idx) => (
                link.scroll 
                ? <li key={idx}><ScrollLink smooth='easeInOutQuad' offset={link.offset}  to={link.name}>{link.label}<Slider /></ScrollLink></li>
                : <li key={idx}>
                     { 
                        isSignedIn && link.label === 'Login'
                         ? <LogoutButton onClick={dbLogout}>Logout</LogoutButton>
                         : <PageButton to={link.to}>{link.label}</PageButton>
                     }
                  </li>
                ))}
        </Menu>
    )
}