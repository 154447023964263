import styled from "styled-components";
import styles from "../../styles";
import {GiCheckMark} from 'react-icons/gi'
import {Link as LinkS} from 'react-scroll'

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    padding: 120px 0 90px;

    &:before {
        content: "";
        position: absolute;
        margin-left: -480px;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background: #f4f5f8;
    }

    @media screen and (max-width: 968px) {
        padding: 10px 0 40px
    }    
`
export const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 968px) {
        flex-direction: column;
    }
`
export const Graphic = styled.div`
    flex: 0 0 50%;
    position: relative;

    & img {
        width: 100%;
        height: auto;
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 15px;
        height: 280px;
        background: ${styles.primary};
        border-radius: 8px;
    }
    @media screen and (max-width: 968px) {
        display: none;
        flex: 0 0 100%;
        padding: 0 10px;
        & img {
            width: 90%;
            margin-bottom: 10px;
        }
        &:before {
            background: transparent;
        }
    }
`
export const InfoSection = styled.div`
    flex: 0 0 50%;
    padding-left: 10px;
    
    @media screen and (max-width: 968px) {
        flex: 0 0 100%
    }
    
`
export const TopLine = styled.p`
    color: ${styles.primary};
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
`
export const Heading = styled.h1`
    margin-bottom: 24px;
    margin-right: 10px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    margin-top: ${({mt}) => (mt ? `${mt}px` : '39.840px')};
    color: ${({ ligthText }) => (ligthText ? 'f7f8fa' : '010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`
export const InfoTitle = styled.div`
    font-family: 'Teko', sans-serif;
    font-size: 70px;
    line-height: 0.9em;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
`
export const Description = styled.p`
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 0;
`

export const Details = styled.div`
    color: #686a6f;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.7em;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
`

const FeaturesListItem = styled.div`
    display: flex;
    color: #686a6f;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.7em;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    /* padding-left: 35px; */

    & svg {
        fill: ${styles.primary};
        margin-right: 30px;
    }

    
    @media screen and (max-width: 375px) {
        font-size: 16px;   
    }
`

export const FeaturesList = ({items}) => {
    return (
        <div>
            {items.map((item, idx) => <FeaturesListItem key={idx}><GiCheckMark />{item}</FeaturesListItem>)}
        </div>
    )
}

export const BtnWrapper = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (min-width: 481px) {
        justify-content: flex-start;
    }
`
export const ActionButton = styled(LinkS)`
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding: 19px 50px 16px;
    background: ${styles.primary};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #fff;
    border-color: transparent;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    outline: none;

    @media screen and (max-width: 768px) {
        margin-top: 20px
    }
    
    
`
export const Badge = styled.div`
    position: relative;
    margin-left: 82px;
    margin-top: 20px;
    padding-top: 20px;

    & span  {
        position: relative;
        display: block;
        padding: 21px 5px;
        width: 90px;
        height: 57px;
        line-height: 24px;
        text-align: center;
        background: #000;
        text-transform: uppercase;
        color: #ffffff;
        border-radius: 50%;
    }
    &:before {
        content: "";
        position: absolute;
        left: -10px;
        top: 0px;
        width: 80px;
        height: 80px;
        background: #e8e9e9;
        border-radius: 50%;
    }

    &:after {
        content: "";
        position: absolute;
        left: -42px;
        top: 0px;
        bottom: 0px;
        border-left: 2px solid ${styles.primary};
    }
`

export const FeaturesSection = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 4px;
    margin-bottom: 20px;

`