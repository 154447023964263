import styled from 'styled-components'
import {Link} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom'
import styles from '../styles'

export const Button = styled(Link)`
    border-radius: 50px;
    background: ${({bg}) => (bg ? bg : styles.primary)};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({color}) => (color ? color : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({bg}) => (bg ? bg : "#000")};
    }
`

export const PageButton = styled(LinkR)`
    border-radius: 50px;
    background: ${styles.primary};
    white-space: nowrap;
    padding: 10px 20px;
    color: #fff;
    font-size: 1rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`
export const LogoutButton = styled.div`
    border-radius: 50px;
    background: ${styles.primary};
    white-space: nowrap;
    padding: 10px 20px;
    color: #fff;
    font-size: 1rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`