export const NavLinks = [
    {
        name: 'home',
        label: 'Home',
        scroll: true,
        offset: -50
    },
    {
        name: 'about',
        label: 'About',
        scroll: true,
        offset: -50
    },
    {
        name: 'services',
        label: 'Services',
        scroll: true,
        offset: -170
    },
    {
        name: 'contact',
        label: 'Contact',
        scroll: true,
        offset: -50
    },
    {
        name: 'login',
        label: 'Login',
        scroll: false,
        isButton: true,
        to: '/login',
    },
]
export const infoSectionOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topline: 'Full Service',
    headline: 'From your great idea to market in one place',
    description: 'Working with a full service agency is more than just convience. It\'s having a partner who sees and understands the big picture the way you do.',
    details: 'We pride ourselves on collaborating with our clients to create thorough develpement plans, tailored to drive the desired outcomes in the most efficient and scaleable way possible. From custom integrations to custom analytics our solutions are built with your goals as the foundation. No out-of-the-box solutions.',
    features: ['Frond-End Design', 'Back-End Dev-Ops', 'Advertising & Marketing'],
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('./assets/svg-1.svg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
}
export const SidebarLinks =  [
    {
        icon: 'account',
        name: 'Account',
        to: 'account'
    },
    {
        icon: 'project',
        name: 'Project',
        to: 'project'
    },
    // {
    //     icon: 'project',
    //     name: 'Projects',
    //     to: 'projects'
    // },
    {
        icon: 'message',
        name: 'Messages',
        to: 'messages'
    },
    {
        icon: 'document',
        name: "Documents",
        to: 'documents'
    },
    {
        icon: 'admin',
        name: "Admin",
        to: 'admin'
    },
]

export const TaskData = [
    {
        title: 'Task One',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        status: "not started"
    },
    {
        title: 'Task Two',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        status: "active"
    },
    {
        title: 'Task Three',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        status: "active"
    },
    {
        title: 'Task Four',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        status: "active"
    },
    {
        title: 'Task Five',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        status: "active"
    },
]