import React from 'react'
import styled from 'styled-components'

const FooterMiddle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #131313;
    color: #fff;
    padding: 10px 0;
    margin-top: 10px;
`
const BrandFootnote = styled.div`

`

const Footer = () => {
    return (
        <>
            <FooterMiddle>
                <div>&copy; 2021 Code Hut</div> 
                <BrandFootnote>a Stradia Brand</BrandFootnote>
            </FooterMiddle>    
        </>
    )
}

export default Footer
