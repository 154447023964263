import { useState } from 'react'
import firebase from 'firebase'
import { Box, Button, Divider, FormControlLabel, List, ListItem, makeStyles, MenuItem, Switch, TextField } from '@material-ui/core'
import { capitalCase } from 'change-case'
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore'
import { useForm, Controller } from 'react-hook-form'
import { getLocal, saveLocal } from '../../appFunctions'
import { useAuth } from '../../Auth'
import { db } from '../../base'
import { AutoFormInput, ListItemLabel, ListItemValue, ProfileForm, sanitizeValue, Wrapper } from './ProfileElements'

const useStyles = makeStyles({
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    listItem: {
        flex: '0 0 50%'
    }
})

const Profile = () => {
    const classes = useStyles()

    const { currentUser } = useAuth()

    const noEditList = ['uid', 'id', "lastUpdated", "created", "isAdmin", "level"]
    const profileProps = ["photoURL", "displayName", "firstName", "lastName", "email", "phoneNumber", "isAdmin", "level", "uid", "id", "lastUpdated"]
    const orderProfilProps = ([n1], [n2]) => {
        if (profileProps.indexOf(n1) < profileProps.indexOf(n2)) return -1
        if (profileProps.indexOf(n1) > profileProps.indexOf(n2)) return 1
        return 0
    }
    const profileRef = firebase.firestore().doc(`users/${currentUser.uid}`)
    const [profile, loading, error] = useDocument(
        profileRef,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        })
    const [editMode, setEditMode] = useState(false)

    const { register, handleSubmit, control } = useForm()
    const onSubmit = formData => console.log(formData)

    const updateProfile = async (profileData) => {
        try {
            const updatedProfile = {
                ...profile.data(), 
                ...profileData,
                lastUpdated: firebase.firestore.Timestamp.now().seconds
            }
            await profileRef.update(updatedProfile)
            // updatedProfile.lastUpdated = updatedProfile.lastUpdated.toDate()
            console.log(updatedProfile)
            // saveLocal('profile', updatedProfile)
            
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Wrapper>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span>Loading Profile...</span>}

            {profile && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>
                        {!editMode && (<Button variant="outlined" onClick={() => setEditMode(true)}>Edit</Button>)}
                        {editMode && (<Button variant="outlined" onClick={() => setEditMode(false)}>Cancel</Button>)}
                    </Box>
                    <ProfileForm onSubmit={handleSubmit(updateProfile)}>
                        <List>
                            {Object.entries(profile.data())
                                .sort(orderProfilProps)
                                .map(([name, value]) => (
                                    <ListItem key={name}>
                                        <ListItemLabel>{capitalCase(name)}</ListItemLabel>
                                        {!editMode && <ListItemValue>{sanitizeValue(value)}</ListItemValue>}
                                        {editMode && (<AutoFormInput {...{ name, value, control, noEditList }} />)}
                                    </ListItem>
                                ))}
                        </List>
                        <Button variant="contained" style={{ background: 'green', color: "#fff" }} type="submit">Save Changes</Button>
                    </ProfileForm>
                </Box>

            )}

        </Wrapper>
    )
}

export default Profile
