import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext } from '../../Auth'

export const PrivateRoute = ({ children, ...rest }) => {
    const { currentUser } = useContext(AuthContext)
    return (
        <Route {...rest} render={() => {
            return !!currentUser
            ? children
            : <Redirect to='/login' />
          }} />
    )
} 