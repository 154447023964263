import styled from "styled-components";
import styles from "../../styles";
import bgPattern from '../../assets/pattern-1.png'

export const Bg = styled.div`
    display: flex;
    justify-content: center;
    padding: 120px 0px 90px;
    background: #222429 url(${bgPattern}) center top repeat;
    color: #ffffff;
`
export const Wrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    padding: 0px 15px;
    align-self: center;

`
export const Box = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 50px 45px;
    background: #1c1e22;
    border-radius: 7px;

    @media screen and (max-width: 968px) {
        margin-bottom: 20px;
    } 
`
export const Number = styled.div`
    flex: 0 0 50%;
    font-size: 50px;
    font-family: 'Teko', sans-serif;
    color: #222429;
    `
export const Graphic = styled.div`
    flex: 0 0 50%;
    font-size: 60px;
    display: flex;
    justify-content: flex-end;
    & svg {
        fill: ${styles.primary};
        stroke: ${styles.primary};
    }
    `
export const BoxTitle = styled.div`
    flex: 0 0 100%;
    font-family: 'Teko', sans-serif;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.1em;
    `
export const Title = styled.h2`
    font-family: 'Teko', sans-serif;
    font-size: 70px;
    line-height: 0.9em;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    `

export const SubTitle = styled.div`
    font-size: 20px;
    font-family: 'Teko', sans-serif;
    line-height: 1.7em;
    font-weight: 300;
    letter-spacing: 0.1em;
    padding: 10px 0px 0px;
    margin-bottom: 30px;
`
